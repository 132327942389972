import React, { useEffect, useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { v4 as uuidv4 } from 'uuid';
import { passPaymentMethodToDropin } from "../../../infra/apis/bookings/requests/Booking";
import globalValues from "../../../infra/apis/globalValues";

const BookingPaymentContainer = ({ paymentMethods, paymentData, paymentToken, makeReservation, setIsPaymentError, setIsPaymentLoading }) => {
  const paymentMethod = {
    "paymentMethods": [
      paymentMethods
    ]
  }

  const makeInstantPayment = async (data) => {
    return new Promise((resolve) => {
      const uniqueID = uuidv4()
      const { paymentMethod } = data
      const paymentRequestBody = {
        amount: {
          currency: "GBP",
          value: paymentData.total
        },
        cloudLocationID: paymentData.cloudLocationID,
        reference: `Online-${paymentData.cloudLocationID}-Ecom-${uniqueID}`,
        siteTransactionRef: paymentData.ID,
        returnUrl: window.location.href,
        store: `Ecommerce_${paymentData.cloudLocationID}`,
        countryCode: "GB",
        shopperEmail: paymentData.email,
        ShopperName: paymentData.name,
        telephoneNumber: paymentData.phoneNumber,
        paymentMethod,
        isTokenized: false,
        APISource: "Web",
        shopperReference: uuidv4(),
        additionalData: {
          "allow3DS2": "true"
        },
      }
      passPaymentMethodToDropin(paymentRequestBody, paymentToken).then((response) => {
        resolve(response)
      }).catch(error => {
        setIsPaymentLoading(false)
        setIsPaymentError(true)

      });
    })

  }
  const handleTransaction = (stage, response) => {
    if (stage === "Authorised" || stage === "Received" || stage === "AuthenticationNotRequired") {
      makeReservation(response)
    }
    else if (stage === "ChallengeShopper" || stage === "PresentToShopper" || stage === "AuthenticationNotRequired" || stage === "RedirectShopper" || stage === "IdentifyShopper") {


    } else {
      setIsPaymentLoading(false)
      setIsPaymentError(true)
    }
  }
  useEffect(() => {
    const initCheckout = async () => {
      const configuration = {
        paymentMethodsResponse: paymentMethod,
        clientKey: globalValues.baseURLS.REACT_APP_PAYMENT_CLIENT_KEY,
        locale: "en-US",
        environment: globalValues.baseURLS.REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
        analytics: {
          enabled: true,
        },
        onSubmit: (state, dropin) => {
          if (state.data.storePaymentMethod === false) {
            setIsPaymentLoading(true)
            makeInstantPayment(state.data)
              .then((response) => {
                if (response.action) {
                  dropin.handleAction(response.action);
                } else {
                  handleTransaction(response.resultCode, response)
                }
              })
          }
        },
        onError: () => {
          console.log("error in payment")
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            hideCVC: false,
            showPayButton: true,
          },
        },
      };
      try {
        const checkout = await AdyenCheckout(configuration)
        checkout
          .create("card", {
            openFirstPaymentMethod: false,
          })
          .mount("#card-container")
      } catch (error) {
        console.log("Error in Card payment")
      }

    };
    if (paymentMethods) initCheckout();
  }, [paymentMethods]);
  return <div id='card-container'></div>;
};
export default BookingPaymentContainer;
